
<template>
    <div>
       <div class="title"> <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>任务结算记录详情  </div>
       <div style="margin-top:10px">
        <el-row :gutter="10">
            <el-col :span="6">
                <div class="grid-content">
                    <div class="textsty">
                       <p> 成功人数</p>
                       <p class="text">{{getdata.successNum}}</p>
                    </div>
                    
               </div>
            </el-col>
             <el-col :span="6">
                <div class="grid-content">
                    <div class="textsty">
                       <p> 成功金额</p>
                       <p class="text">{{getdata.successAmount}}</p>
                    </div>
                    
               </div>
            </el-col>
             <el-col :span="6">
                <div class="grid-content">
                    <div class="textsty">
                       <p> 失败人数</p>
                       <p class="text1">{{getdata.failNum}}</p>
                    </div>
                    
               </div>
            </el-col>
             <el-col :span="6">
                <div class="grid-content">
                    <div class="textsty">
                       <p> 失败金额</p>
                       <p class="text1">{{getdata.failAmount}}</p>
                    </div>
                    
               </div>
            </el-col>
       </el-row>
       </div>
       <el-card style="margin-top:10px">
          <div slot="header" class="clearfix">
              <span>筛选条件</span>
          </div>
           <el-form :model="rule"   label-width="100px" class="demo-ruleForm">
                <el-form-item label="姓名" class="item">
                     <el-input v-model="rule.name" style="width:200px" ></el-input>
                 </el-form-item>
                  <el-form-item label="身份证号" class="item">
                     <el-input v-model="rule.idCard" style="width:260px" ></el-input>
                 </el-form-item>
                  <el-form-item label="交易状态" class="item">
                     <el-select v-model="rule.state" placeholder="请选择" style="width:140px" clearable>
                        <el-option
                            v-for="item in tranList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                 </el-form-item> 
                   <!-- <el-form-item label-width="150px"  label="统一社会信用代码" class="item">
                     <el-input v-model="rule.creditCode" style="width:300px" ></el-input>
                 </el-form-item> -->
                    <el-button type="primary" style="margin-left:40px;" @click="getDataList(1)">查询</el-button> 
                    <el-button type="primary" style="margin-left:40px;" @click="dialogVisible=true">查看结算单</el-button> 
       
             </el-form>
          </el-card>
        <!--  -->
        <el-card  style="margin-top: 10px">
    <el-table
       v-loading="load"
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData"
      style="width: 100%;margin-top: 10px">
      <el-table-column
        header-align="center"
        align="center"
        prop="taskName"
        label="任务名称"
        >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="batchNo"
        label="订单号（批次号）"
        >
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="name"
        width="130"
        label="姓名">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="idCard"
        label="身份证号">
      </el-table-column>
      <!-- <el-table-column
       header-align="center"
        align="center"
        prop="creditCode"
        label="统一社会信用代码">
      </el-table-column> -->
      <el-table-column
       header-align="center"
        align="center"
        prop="state"
        label="交易状态">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="bankNum"
        label="银行卡">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="amount"
        width="130"
        label="金额">
      </el-table-column>
       <el-table-column
       header-align="center"
        align="center"
        prop="remarks"
        label="备注">
      </el-table-column>
       <el-table-column
       header-align="center"
        align="submitter"
        prop="errMsg"
        label="失败原因">
      </el-table-column>
    </el-table>
     <el-pagination
          
            background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="currPage"
            :page-sizes="[10, 20,30, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            class="text_center"
            layout="total,  prev, pager, next, sizes,jumper"
             style="text-align:right;margin-top:30px"
     ></el-pagination> 

      </el-card>  
      
        <el-dialog
        :fullscreen=true
        :visible.sync="dialogVisible">
        <div style="margin: auto;text-align: center;"><img :src="getdata.settlementFileUrl" alt="" ></div>
        <!-- {{getdata.settlementFileUrl}} -->
        
        </el-dialog>
    </div>
</template>
<script>
import {getOrderSettlemen,DetailPageList} from  "@/api/task.js";
export default {
    name: '',
    data() {
        return {
         getdata:{},
         load:false,
         rule:{
            name:"",
            idCard:"",
            state:"",
            // creditCode:""
        },
         tranList: [{
          value: '1',
          label: '成功'
        }, {
          value: '2',
          label: '失败',
        }],
        pageSize: 10,
          totalCount: 0,//数据长度
          currPage: 1,
          tableData:[],
          dialogVisible:false
        }
    },
    mounted(){
        this.now()
        this.getDataList()
    },
    methods:{
        now(){
         getOrderSettlemen(this.$route.query.orderId).then((data=>{
            if (data && data.code==200) {
                     this.getdata=data.data
                    }
            }))
        },
        getDataList(a){
            this.load=true
            if (a) {  //判断是不是搜索
                this.currPage=1 
                this.pageSize=10
            }
            let value={
                name:this.rule.name,
                idCard:this.rule.idCard,
                orderId:this.$route.query.orderId,
                state:this.rule.state,
                // creditCode:this.rule.creditCode,
                "current": this.currPage,
                "size": this.pageSize,
            }
            DetailPageList(value).then((data=>{
                 this.load=false
                    if (data.code==200) {
                    this.tableData=data.data.records
                    this.totalCount=data.data.total
            }
            }))
        },

   // 每也数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.currPage = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.currPage = val;
        this.getDataList();
      },
      seeStatement(){

      }
    }
}
</script>
<style scoped>
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.icon{
    font-size: 30px;
    transform: translateY(5px);
     color: #b5b5b5;
    margin-right: 5px;
    cursor: pointer;
}
.grid-content {
    border-radius: 1px;
    background: #fff;
    text-align: center;
    height: 160px;
    overflow: hidden;
    position: relative;
}
.textsty{
    width: 200px;
    height: 100px;
    margin: auto;
    position: absolute;
    font-size: 14px;
    /* background: darkcyan; */
    top: 0px;left: 0;right: 0;bottom: 0;
}
.text{
    color: #0fa554;
}
.text1{
    color: red;
}
.clearfix span{
     font-size: 15px;
}
.item{
    display: inline-block;
}
</style>
